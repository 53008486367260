// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

if(SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    release: "horizon@" + process.env.npm_package_version,
    environment: process.env.VERCEL_ENV,
    tracesSampleRate: 1.0,
  });
}
