export const PermissionsDesc = {
  read: "Give read permissions to domain module and it's data",
  write: "Give write permissions to domain module and it's data",
  sync: "Give sync permissions to domain module and it's data",
  details: "Give details read permissions to domain module and it's data",
  renew_read: "Give renew read permissions to domain module and it's data",
  renew_write: "Give renew write permissions to domain module and it's data"
}

export const DomainDefaultPermissions = {
  read: false,
  write: false,
  sync: false,
  details: false,
  renew_read: false,
  renew_write: false
}
