export const constConfig = {
  APP_NAME : "Horizon",
  navigation: {},
  permissions: {},
  notifications: {}
}

export const notificationActionsList = {
  domain: [
    "expiring-domains"
  ]
}
