import { constConfig } from "../config/const.config"

/**
 * Default permissions import
 */
import { DomainDefaultPermissions } from "./domain/config/permissions"
import { UserDefaultPermissions } from "./user/config/permissions"
import { RoleDefaultPermissions } from "./role/config/permissions"
import { DashboardDefaultPermissions } from "./dashboard/config/permissions"
import { NotificationDefaultPermissions} from "./notification/config/permissions"
import { AuditDefaultPermissions } from "./audit/config/permissions"

/**
 * Navigation import
 */
import { DomainNavigation } from "./domain/config/navigation"
import { UserNavigation } from "./user/config/navigation"
import { RoleNavigation } from "./role/config/navigation"
import { DashboardNavigation } from "./dashboard/config/navigation"
import { NotificationNavigation } from "./notification/config/navigation"
import { AuditNavigation } from "./audit/config/navigation"

/**
 * Notification functions import
 */
import { DomainNotifications } from "./domain/config/notification"

/**
 * Modules registry
 */
export const moduleList = {
  dashboard: {
    permissions: DashboardDefaultPermissions,
    navigation: DashboardNavigation
  },
  domain: {
    permissions: DomainDefaultPermissions,
    navigation: DomainNavigation,
    notifications: DomainNotifications
  },
  user: {
    permissions: UserDefaultPermissions,
    navigation: UserNavigation
  },
  role: {
    permissions: RoleDefaultPermissions,
    navigation: RoleNavigation
  },
  notification: {
    permissions: NotificationDefaultPermissions,
    navigation: NotificationNavigation
  },
  audit: {
    permissions: AuditDefaultPermissions,
    navigation: AuditNavigation
  }
}

export const propagate = () => {

  Object.keys(moduleList).forEach((key) => {
    const _tmp = moduleList[key]

    if (!constConfig.permissions.hasOwnProperty(key)) {
      constConfig.permissions[key] = _tmp.permissions
    }
    if (!constConfig.navigation.hasOwnProperty(key)) {
      constConfig.navigation[key] = _tmp.navigation
    }
    if (!constConfig.notifications.hasOwnProperty(key)) {
      constConfig.notifications[key] = _tmp.notifications
    }

  });

}

export default propagate
