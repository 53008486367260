export { reportWebVitals } from 'next-axiom'

import 'react-toastify/dist/ReactToastify.css'
import '../styles/globals.css'

import { SessionProvider } from 'next-auth/react'
import { ThemeProvider } from 'next-themes'

import {constConfig} from "../config/const.config"
import {NextSeo} from "next-seo"
import React, { useRef, useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import * as gtag from '../lib/analytics/gtag'
import Script from 'next/script'
import propagate from "../modules"
import { toast } from 'react-toastify'

const MyApp = ({ Component, pageProps: { session, ...pageProps } }) => {

  propagate()

  const router = useRouter()
  const [connected, setConnected] = useState(true)
  const connectionToast = useRef(null)

  useEffect(() => {
    // @ts-ignore
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator && window.workbox !== undefined) {
      // @ts-ignore
      const wb = window.workbox

      const promptNewVersionAvailable = event => {
        if (confirm('A newer version of this web app is available, reload to update?')) {
          wb.addEventListener('controlling', event => {
            window.location.reload()
          })

          wb.messageSkipWaiting()
        }
      }

      wb.addEventListener('waiting', promptNewVersionAvailable)

      wb.register()
    }
  }, [])

  useEffect(() => {
    if(connected) {
      toast.update(connectionToast.current, {
        render: "Connection to server established",
        type: toast.TYPE.SUCCESS,
        closeButton: true,
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true
      })
    } else {
      connectionToast.current = toast("Connection to server lost", {
        type: toast.TYPE.WARNING,
        closeButton: false,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false
      })
    }
  }, [connected])

  useEffect(() => {
    if(typeof window !== 'undefined') {
      window.addEventListener('offline', function(e) { setConnected(false) });
      window.addEventListener('online', function(e) { setConnected(true) });
    }
  })

  useEffect(() => {
    const handleRouteChange = (url) => {
      gtag.pageview(url)
    }
    router.events.on('routeChangeComplete', handleRouteChange)
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [router.events])

  return (
    <SessionProvider session={session} >

      <ThemeProvider defaultTheme={'system'} attribute="class">

        {/* @ts-ignore */}
        <NextSeo
          title={ constConfig.APP_NAME }
        />

        <Script
          strategy="afterInteractive"
          src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
        />
        <Script
          id="gtag-init"
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
          }}
        />
        <Script
          id="cf-beacon"
          strategy="afterInteractive"
          src={`https://static.cloudflareinsights.com/beacon.min.js`}
          data-cf-beacon='{"token": "2a1e7289e62f4febb8d9d5ed7669fa30"}'
        />
        <Script
          id="nr"
          strategy="afterInteractive"
          src={'/lib/nr.js'}
        />

        <Component {...pageProps} />

      </ThemeProvider>

    </SessionProvider>
  )

}

export default MyApp
